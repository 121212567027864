.BillBord {
  z-index: 0;
  background-color: rgba(214, 214, 214, 0.814);
  min-width: 60vw;
  min-height: 80%;
  max-height: 95vh;
  margin: auto;
  border-radius: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  overflow: auto;
  overflow-x: hidden;
}
.backGroundLogo {
  /* background-image: url(../../assets/icon_stor.png); */
  position: absolute;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -999;
  height: 100%;
  width: 100%;
}
.DialogWindow {
  background-color: rgba(0, 153, 153, 1);
}
.headerText {
  flex: wrap;
}
.PrimaryTextColor {
  color: rgba(0, 0, 0, 0.7);
  flex: wrap;
  text-align: center;
}
.AlertBox {
  background-color: rgba(244, 23, 32, 0.6);
  width: 25vw;
  height: 25vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.loader {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ThumbUp {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlay {
  width: 100%;
  height: 100%;
  opacity: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  background: rgba(0, 0, 0, 0.3);
  display: block;
  position: fixed;
  z-index: 100;
}

.AlertBoxSuccess {
  background-color: rgba(12, 112, 12, 0.6);
  border-radius: 10px;
  box-shadow: darkblue;
  min-width: 25vw;
  min-height: 25vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  overflow: auto;
}
.inputFields {
  color: white !important;
}
.MuiInputBase-input {
  color: 'white';
}
.Banner {
  background-color: rgba(255, 0, 0, 0.5);
  width: 70%;
  height: 40%;
  border-radius: 20px;
  z-index: 9999;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 40%;
  margin-top: -50px;
}
.inputField {
  font-size: 30 !important;
}
.goBack {
  position: absolute;
  top: 10;
  left: 10;
}
.ListNumbers {
  font-size: 40;
}
.retningslinjer {
  width: 90%;
  font-size: 16px;
  text-align: initial;
}
.retningslinjer span {
  color: #000;
  position: absolute;
  left: 0;
}

.retningslinjerbackground {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background: rgb(180, 186, 218);
  background: radial-gradient(
    circle,
    rgba(180, 186, 218, 1) 4%,
    rgba(38, 99, 140, 1) 69%,
    rgba(51, 63, 72, 1) 100%
  );
}
