@media only screen and (max-width: 650px) {
  .page-header {
    max-height: none;
  }
}

.FrontPageLogo {
  background-image: url(./assets//img/logo.png);
  position: absolute;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
}
.footerText {
  font-size: 100%;
}
.ConfirmationBox {
  opacity: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.headText {
  font-weight: bold;
}
